import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Navbar from './PitchNavbar'

import '../layouts/all.styl';
import '../layouts/typography.styl';
import '../layouts/flexboxgrid.styl';

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet title="John Dilworth" />
    <Navbar />
    <div>{children}</div>
  </div>
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper
