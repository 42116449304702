import React from 'react'
import Link from 'gatsby-link'
import logo from '../img/7knot.svg'


const PitchNavbar = () => (
  <nav className="navbar is-transparent">
    <div className="navbar-brand">
    <Link to="/" className="">
     <img src={logo} className="navbar-logo" alt="" />
      <span className="navbar-item navbar-title">
        John Dilworth
      </span>
      <span className="navbar-item navbar-title-mobile">
        JD
      </span>
    </Link>
    </div>

    <div className="navbar-links">

      <Link className="navbar-item"  activeClassName="active" to="/resume">
        Resume
      </Link>
  
    </div>
  </nav>
)

export default PitchNavbar
